export const apiEndpoints = {
	getAvailableMsisdn: "/activation-api/getAvailableMsisdn",
	fillForm: "/activation-api/fillForm",
	borcKontrol: "/activation-api/borcKontrol",
	getStreets: "/activation-api/getStreets",
	createOTP: "/activation-api/createOTP",
	checkOTP: "/activation-api/checkOTP",
	isPrePos: "/activation-api/isPosPre",
	validateTCKimlik: "/activation-api/validateTCKimlik"
};

export const cmsApiEndpoints = {
	GetPackages: "/cms-api/GetPackages",
	GetDealerByFilter: "/cms-api/GetDealers",
	GetAllPackage: "/cms-api/GetAllPackageMobile"
};

export const imageApiEndpoints = {
	saveImage: "/image-api/saveImage",
};

export const clickApiEndpoints = {
	createIssue: "/click-api/createIssue",
};
