import Container from 'App/components/Container';
import PageWrapper from 'App/components/PageWrapper';
import IconCopy from 'assets/svg/IconCopy';
import IconError from 'assets/svg/IconError';
import IconSuccess from 'assets/svg/IconSuccess';
import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { copyToClipboard, getFormattedMsisdn, getMappedTariffId, getSelectObjects } from 'App/helpers/functions';
import api from 'App/api';

import './style.scss';
import { LoadingSpinnerActionTypes } from 'store/loadingSpinner/types';
import { apiEndpoints, clickApiEndpoints } from 'App/api/endpoints';
import { ResultActionTypes } from 'store/result/types';
import Modal from 'App/components/Modal';
import { AddressInfoActionTypes } from 'store/addressInfo/types';
import { PersonalInfoActionTypes } from 'store/personalInfo/types';
import { PackageInfoActionTypes } from 'store/packageInfo/types';
import { MsisdnActionTypes } from 'store/msisdn/types';
import { Redirect, useLocation } from 'react-router';
import routes from 'App/routes';
import citiesJSON from 'data/cities.json';
import districtsJSON from 'data/districts.json';
import moment from 'moment';

const GenericResultPage = () => {
  const { t, i18n } = useTranslation();
  const confirmationCodeRef = useRef<HTMLSpanElement>(null);

  const appState: ApplicationState = useSelector((state: ApplicationState) => state, shallowEqual);

  const [showFillFormFailedModal, setShowFillFormFailedModal] = useState(false);

  interface LocationState {
    from: {
      pathname: string;
    };
    hasPrev?: boolean;
    queryCode?: string;
  }
  const location = useLocation<LocationState>();

  const hasPrev = useMemo(() => location.state?.hasPrev || false, [location]);

  var queryCode = useMemo(() => location.state?.queryCode || '', [location]);
  if (queryCode == null || queryCode == 'null' || queryCode == '' || queryCode == undefined) {
    //console.log("querycode empty ");
    queryCode = '0';
  } else {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?code=' + queryCode;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  const cities = getSelectObjects(citiesJSON.filter((item: any) => item.id === appState.addressInfo.city.toString()));
  //console.log('cities', appState.addressInfo.city.toString())

  const [formData, setFormData] = useState({
    MSISDN: appState.msisdn.number,
    NAME: appState.personalInfo.nameSurname,
    ID_NO: appState.personalInfo.idNumber,
    EMAIL: appState.personalInfo.email,
    CONTACT_NO: appState.personalInfo.contactNumber,
    FATHER_NAME: appState.personalInfo.fathersName,
    BIRTH_DATE: appState.personalInfo.dob, // DD.MM.YYYY (moment(appState.personalInfo.dob?)).format('DD.MM.YYYY')
    TIMEOUT:
      appState.msisdn.timeout === undefined || appState.msisdn.timeout === null || appState.msisdn.timeout.length === 0
        ? new Date()
        : appState.msisdn.timeout,
    LANG_ID: i18n.language === 'tr' ? '1' : '2',
    PROMO_CODE: queryCode,
    FROM_PAGE: appState.msisdn.returnPage === routes.mnt ? '15855' : appState.msisdn.returnPage === routes.switch ? '15858' : '15856',
    DESC:
      appState.msisdn.returnPage === routes.mnt
        ? 'MNT Talebi'
        : appState.msisdn.returnPage === routes.switch
        ? 'Switch Talebi '
        : 'Faturalı Hat Talebi',
    teslimatTipi: appState.addressInfo.choise === '1' ? '15893' : '15892',
    acikAdres: ` ${appState.personalInfo.address} ${appState.personalInfo.streetName} ${
      getSelectObjects(
        districtsJSON.filter((item: any) => {
          return item.id === appState.personalInfo.district;
        })
      ).length > 0
        ? getSelectObjects(
            districtsJSON.filter((item: any) => {
              return item.id === appState.personalInfo.district;
            })
          )[0].label
        : ''
    }`,
    DEALER: appState.addressInfo.dealer,
    SCHOOL: appState.personalInfo.school,
    sehir:
      getSelectObjects(citiesJSON.filter((item: any) => item.id === appState.addressInfo.city.toString())).length > 0
        ? getSelectObjects(citiesJSON.filter((item: any) => item.id === appState.addressInfo.city.toString()))[0].label
        : '',
    PACKAGE_NAME: appState.packageInfo.name === undefined || appState.packageInfo.name === null ? ' ' : appState.packageInfo.name,
    PACK_IDS: appState.packageInfo.packageId,
    PACK_PRICE: appState.packageInfo.price,
    // FillFormResult: appState.result.confirmationCode
  });

  const dispatch = useDispatch();

  const copyToValueClipboard = (e: any) => {
    const copyValue = confirmationCodeRef.current?.innerText || '';
    //console.log(copyValue);
    if (copyToClipboard(copyValue)) {
      alert(t('resultPage.copyCodeSuccess'));
    } else {
      alert(t('resultPage.copyCodeFail'));
    }
  };

  // console.log("formData : " + JSON.stringify(formData));
  // console.log("appState : " + JSON.stringify(appState));

  const resetUserDataOnStore = useCallback(() => {
    dispatch({
      type: AddressInfoActionTypes.DELETE,
    });
    dispatch({
      type: PersonalInfoActionTypes.DELETE,
    });
    dispatch({
      type: PackageInfoActionTypes.DELETE,
    });
    dispatch({
      type: MsisdnActionTypes.DELETE,
    });
    // dispatch({
    // 	hasPrev : false,
    // });
  }, [dispatch]);

  const callFillForm = useCallback(() => {
    // Exit if already got the confirmation code

    if (appState.result?.status !== null && appState.result?.status !== '') {
      return;
    }

    dispatch({
      type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
    });

    let fData: any = {};
    if (appState.msisdn.returnPage !== routes.mnt && appState.msisdn.returnPage !== routes.switch) {
      const apiEndpoint1 = apiEndpoints.fillForm;
      //console.log("apiEndpoint1 formData fill : ",apiEndpoint1,formData.BIRTH_DATE,formData )
      console.log('fillForm => ', formData);
      api
        .post(apiEndpoint1, formData)
        .then(response => {
          const data = response.data;

          //	console.log("confirmationCode Service fill " + data.CONFIRMATION_CODE);

          fData = {
            ...formData,
            FillFormResult: data.CONFIRMATION_CODE,
          };
          //	console.log('fData fill', fData);
          // setFormData(fData)
          //	console.log('restun Data fill', data);

          if (data.CONFIRMATION_CODE) {
            dispatch({
              type: ResultActionTypes.SAVE,
              payload: {
                msisdn: data.RESERVED_MSISDN,
                confirmationCode: data.CONFIRMATION_CODE.toString(),
              },
            });
          } else {
            setShowFillFormFailedModal(true);
            return;
          }
        })
        .catch(e => {
          console.log('catch fill ', e);

          setShowFillFormFailedModal(true);
        })
        .then(() => {
          // dispatch({
          // 	type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
          // });
        });
    } else {
      fData = formData;
    }

    const apiEndpoint = clickApiEndpoints.createIssue;

    // console.log("FormData : " + JSON.stringify(formData));

    // console.log("appState Before" + JSON.stringify(appState.result));

    setTimeout(() => {
      api
        .post(apiEndpoint, fData)
        .then(response => {
          //console.log("Test : " + response.data);
          const data = response.data;

          if (data.CONFIRMATION_CODE) {
            dispatch({
              type: ResultActionTypes.SAVE,
              payload: {
                msisdn: data.RESERVED_MSISDN,
                clickNo: data.CONFIRMATION_CODE,
                confirmationCode: fData.FillFormResult,
                status: 'SUCCESS',
              },
            });

            //console.log("appState last " + JSON.stringify(appState.result));
            //resetUserDataOnStore();//TODO remove comment
            setTimeout(() => {
              resetUserDataOnStore();
            }, 3000);
            return true;
          }
          dispatch({
            type: ResultActionTypes.SAVE,
            payload: {
              status: 'FAIL',
            },
          });
          return false;
        })
        .catch(e => {
          console.log('cathc', e);
          setShowFillFormFailedModal(true);
        })
        .then(() => {
          dispatch({
            type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
          });
        });
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    callFillForm();
  }, [callFillForm]);

  const FillFormFailedModal = () => (
    <Modal show={showFillFormFailedModal}>
      <div className="Modal__content Result__modal">
        <IconError className="Modal__error-icon" />
        <h2 className="Modal__title">{t('resultPage.fillFormFailedModalTitle')}</h2>
        <p className="Modal__description">{t('resultPage.fillFormFailedModalText')}</p>
        <button
          type="button"
          className="button Modal__button"
          onClick={() => {
            setShowFillFormFailedModal(false);
            //callFillForm();
            if (hasPrev !== true) {
              return <Redirect to={routes.root} />;
            }
          }}
        >
          {t('resultPage.tryAgain')}
        </button>
      </div>
    </Modal>
  );

  interface SuccessProps {}
  const Success: React.FC<SuccessProps> = () => {
    return (
      <div className="ResultPage__success">
        <IconSuccess className="ResultPage__success-icon" />
        <h2 className="ResultPage__title">{t('resultPage.successTitle')}</h2>
        <div className="ResultPage__description">
          <p>
            {t('resultPage.thanksForChoosingUs')}
            {appState.result.clickNo} {t('resultPage.thanksForChoosingUs2')}
          </p>
		  <span style={{fontWeight:'bold', color:'black'}}>{appState.result.confirmationCode == undefined ||
            appState.result.confirmationCode == null ||
            appState.result.confirmationCode.length == 0
              ? ''
              : t('resultPage.labelVerificationCode') + ': ' + appState.result.confirmationCode}</span>
          <p>{t('resultPage.resultMagazaTakeDesc')}</p>
        </div>
        <div style={{ height: 270 }} />
        {/* <div className="PersonalInfo__grid">
          <div className="grid">
            <div className="grid__row">
              <div className="grid__column">
                <div className="PersonalInfo__card">
                  <h2 className="PersonalInfo__cardHeader">{t('idInfo.subHeader')}</h2>
                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.msisdn')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.MSISDN}</div>
                  </div>
                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.selectedPackage')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.PACKAGE_NAME}</div>
                  </div>

                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.selectedPackagePrice')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.PACK_PRICE}</div>
                  </div>

             

                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> </div>
                    <div className="grid__column PersonalInfo__cardLabelLeft">
                      <br></br>{' '}
                    </div>
                  </div>

                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.labelNameSurname')} </div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.NAME} </div>
                  </div>

                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.labelIdNumber')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.ID_NO}</div>
                  </div>

                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.labelFathersName')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.FATHER_NAME} </div>
                  </div>
                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.labelEmail')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.EMAIL} </div>
                  </div>
                  <div className="grid__row">
                    <div className="grid__column PersonalInfo__cardLabel"> {t('personalInfo.labelContactNumber')}</div>
                    <div className="grid__column PersonalInfo__cardLabelLeft"> {formData.CONTACT_NO} </div>
                  </div>
                  <div className="grid__column PersonalInfo__cardLabelLeft">
                    {' '}
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  };
  interface LocationState {
    from: {
      pathname: string;
    };
    hasPrev?: boolean;
    queryCode?: string;
  }
  interface FailProps {}
  const Fail: React.FC<FailProps> = () => {
    return (
      <div className="ResultPage__fail">
        <IconError className="ResultPage__fail-icon" />
        <h2 className="ResultPage__title">{t('resultPage.failTitle')}</h2>
        <p className="ResultPage__text">{t('resultPage.failText')}</p>
      </div>
    );
  };

  if (hasPrev !== true) {
    return <Redirect to={routes.root} />;
  }

  return (
    <>
      <PageWrapper>
        <Container className="Container--align-contents-middle Container--narrow">
          {appState.result.status === 'SUCCESS' ? (
            <Success />
          ) : appState.result.status === 'FAIL' ? (
            <Fail />
          ) : (
            <div style={{ height: '50vh' }}></div>
          )}
        </Container>
      </PageWrapper>
      <FillFormFailedModal />
    </>
  );
};

export default GenericResultPage;
