export interface PersonalInfo {
	nameSurname: string;
	contactNumber: string;
	idNumber: string;
	dob: string;
	fathersName: string;
	email: string;
	idType: string;
	city: string;
	district: string;
	street: string;
	streetName: string;
	address: string;
	cityName: string;
	distirctName: string;
	school?: string;
}

export enum PersonalInfoActionTypes {
	SAVE_PERSONAL_INFO = "@@personalInfo/SAVE",
	DELETE = "@@personalInfo/DELETE",
}

export type PersonalInfoState = {
	readonly nameSurname: string;
	readonly contactNumber: string;
	readonly idNumber: string;
	readonly dob: string;
	readonly fathersName: string;
	readonly email: string;
	readonly idType: string;
	readonly city: string;
	readonly district: string;
	readonly street: string;
	readonly streetName: string;
	readonly address: string;
	readonly cityName: string;
	readonly distirctName:string;
	readonly school:string;
};
