import Checkbox from "App/components/Checkbox";
import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router";
import OnBilgilendirmeFormu from "./OnBilgilendirmeForm";
import PhotoField from "./PhotoField";
import routes from "App/routes";

import "./style.scss";
import InputInfoTooltip from "App/components/InputInfoTooltip";
import AbonelikSozlesmesi from "./AbonelikSozlesmesi";
import { query } from "winston";
import { PackageInfoState } from "store/packageInfo/types";
import { ApplicationState } from "store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { MsisdnState } from "store/msisdn/types";
import { PersonalInfo } from "store/personalInfo/types";
import { AddressInfo } from "store/addressInfo/types";
import { ResultActionTypes } from "store/result/types";
import ProcessBar from "App/components/ProcessBar";
import { getSelectObjects } from "App/helpers/functions";
import districtsJSON from "data/districts.json";

const IdInfoPage = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const dispatch = useDispatch();

	const [accepted, setAccepted] = useState(false);
	const [frontside, setFrontside] = useState();
	const [backside, setBackside] = useState();
	const [selfie, setSelfie] = useState();

	// const test = getSelectObjects(
	// 	districtsJSON.filter((item: any) => {
	// 		return item.id === addressInfo.district;
	// 	})
	// )[0].label

	const selectedPackage: PackageInfoState = useSelector(
		(state: ApplicationState) => state.packageInfo,
		shallowEqual
	);

	const person = useSelector(
		(state: ApplicationState) => state.personalInfo,
		shallowEqual
	);

	const adres: AddressInfo = useSelector(
		(state: ApplicationState) => state.addressInfo,
		shallowEqual
	);

	const msisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	const handleAccept = () => {
		setAccepted((prevState) => !prevState);
	};

	/** Disable direct access to form from url */
	interface LocationState {
		from: {
			pathname: string;
		};
		hasPrev?: boolean;
		queryCode?: string;
		operation?: string;
	}
	const location = useLocation<LocationState>();

	const hasPrev = useMemo(() => location.state?.hasPrev || false, [location]);

	const operation = useMemo(() => location.state.operation || false, [location]);

	const onClickHandler = () => {

		dispatch({
			type: ResultActionTypes.DELETE,

		});
		history.push({
			pathname: routes.genericResult,
			state: {
				hasPrev: true,
				queryCode: queryCode,
			},
		});
		console.log(history.location.pathname);
	};


	const queryCode = useMemo(() => location.state?.queryCode || "", [location]);
	if (queryCode == null || queryCode == "null" || queryCode == "" || queryCode == undefined) {
		console.log("querycode empty ")
	} else {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=' + queryCode;
		window.history.pushState({ path: newurl }, '', newurl);
	}

	if (hasPrev !== true) {
		return <Redirect to={routes.root} />;
	}

	return (
		<>
			<PageWrapper>
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar fromPage={msisdn.returnPage} step={msisdn.returnPage.length == 0 ? 4 : 3} />
						</div>
					</div>
				</div>
				<div className="Container--align-contents-middle Container--align-contents-max-width-27" >
					<h2 className="heading heading--navy heading--center">
						{t("idInfo.title")}
					</h2>
					<div className="PersonalInfo__grid">
						<div className="grid">
							<div className="grid__row">
								<div className="grid__column" >
									<div className="PersonalInfo__card">
										<h2 className="PersonalInfo__cardHeader">
											{t("idInfo.subHeader")}
										</h2>
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.msisdn")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {msisdn.number}</div>
										</div>
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.selectedPackage")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {selectedPackage.name}</div>
										</div>
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.selectedPackagePrice")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {selectedPackage.price}</div>
										</div>

										{/* {(msisdn.returnPage == undefined || msisdn.returnPage == null || msisdn.returnPage.length == 0) ? <div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.deliveryAmount")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" >  {t("personalInfo.deliveryAmountAns")}</div>
										</div> : ""} */}

										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > </div>
											<div className="grid__column PersonalInfo__cardLabelLeft" ><br></br> </div>
										</div>

										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelNameSurname")} </div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {person.nameSurname} </div>
										</div>

										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelIdNumber")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {person.idNumber}</div>
										</div>

										{/* <div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelFathersName")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {person.fathersName} </div>
										</div> */}
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelEmail")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {person.email}  </div>
										</div>
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelContactNumber")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" > {person.contactNumber} </div>
										</div>

										{/* {(msisdn.returnPage == undefined || msisdn.returnPage == null || msisdn.returnPage.length == 0)
											? <div className="grid__row">
												<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.deliveryAmount")}</div>
												<div className="grid__column PersonalInfo__cardLabelLeft" >  {t("personalInfo.deliveryAmountAns")}</div>
											</div> : ""} */}

										{/* {(msisdn.returnPage == undefined || msisdn.returnPage == null || msisdn.returnPage.length == 0) ?
											<div className="grid__row">
												<div className="grid__column PersonalInfo__cardLabel" > {t("addressInfo.labelAddress")}</div>
												<div className="grid__column PersonalInfo__cardLabelLeft" >
													{console.log(person)}
													<div>{person.address} {person.streetName}
														<div> {getSelectObjects(
															districtsJSON.filter((item: any) => {
																return item.id === person.district;
															})
														)[0].label}</div>
														<div>{person.cityName} </div>
													</div> </div>
											</div>
											: ""} */}

										<div className="grid__column PersonalInfo__cardLabelLeft" > <br></br></div>

										{(msisdn.returnPage == undefined || msisdn.returnPage == null || msisdn.returnPage.length == 0) ?

											<div className="grid__row">
												<div className="grid__column PersonalInfo__cardLabel" > {t("idInfo.lblDeliveryType")}</div>
												<div className="grid__column PersonalInfo__cardLabelLeft" > {adres.choise === "1" ? t("idInfo.deliveryTypeH") :
													t("idInfo.deliveryTypeD")} </div>
											</div>
											: ""
										}
										{(msisdn.returnPage == undefined || msisdn.returnPage == null || msisdn.returnPage.length == 0) && adres.choise === "2" ?
											<div className="grid__row">
												<div className="grid__column PersonalInfo__cardLabel" > {t("idInfo.labelDealerName")}</div>
												<div className="grid__column PersonalInfo__cardLabelLeft" >{adres.dealer}  </div>
											</div>
											: ""}


										<div className="PersonalInfo__buttons">
											<button
												type="submit"
												className="button IdInfo__button"
												onClick={onClickHandler}
											>
												{t("idInfo.buttonConfirm")}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="IdInfo__buttons">
						<button
							type="button"
							className="button"
							disabled={
								!accepted || !frontside || !backside || !selfie
							}
							onClick={() => {
								history.push({
									pathname: routes.result,
									state: {
										hasPrev: true,
										queryCode: queryCode
									},
								});
							}}
						>
							{t("idInfo.buttonConfirm")}
						</button>
					</div> */}
				</div>
			</PageWrapper>
		</>
	);
};

export default IdInfoPage;
