import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Container from 'App/components/Container';
import PageWrapper from 'App/components/PageWrapper';
import './style.scss';
import { useEffect } from 'react';

//import Select from "App/components/Select";
import NumberFormat from 'react-number-format';
import {
  containsOnlyText,
  isDateValid,
  //isPhoneNumberValid,
  isTwoOrMoreWords,
} from 'App/helpers/validators';
import { getSelectObjects, moveToItemsWithKeysToBeginning, validateIdNumber } from 'App/helpers/functions';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import routes from 'App/routes';
import { AddressInfoFormInputs, PersonalInfoFormInputs } from 'type';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PersonalInfoActionTypes, PersonalInfoState } from 'store/personalInfo/types';
import idTypesJSON from 'data/id-types.json';
import occupationsJSON from 'data/occupations.json';
import nationalitiesJSON from 'data/countries.json';
import gendersJSON from 'data/genders.json';
import Modal from 'App/components/Modal';
import IconError from 'assets/svg/IconError';
import InputInfoTooltip from 'App/components/InputInfoTooltip';
import { LoadingSpinnerActionTypes } from 'store/loadingSpinner/types';
import { apiEndpoints } from 'App/api/endpoints';
import api from 'App/api';
import CountryCodes from 'App/components/CountryCodes';
import { ApplicationState } from 'store';
//import { da } from "date-fns/locale";
import TextField from '@mui/material/TextField';
import { PackageInfoState } from 'store/packageInfo/types';
import { MsisdnState } from 'store/msisdn/types';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useFormControl } from '@mui/material/FormControl';
import ProcessBar from 'App/components/ProcessBar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import moment from 'moment';
import FormHelperText from '@mui/material/FormHelperText';
import axios, { AxiosRequestConfig } from 'axios';
import xml2js from 'xml2js';
import RadioButton from 'App/components/RadioButton';
import SelectApp from 'App/components/Select';
import citiesJSON from 'data/cities.json';
import districtsJSON from 'data/districts.json';
import { AddressInfoState } from 'store/addressInfo/types';

const PersonalInfoPage = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    // trigger,
    // reset,
  } = useForm<PersonalInfoFormInputs>();
  const history = useHistory();

  const dispatch = useDispatch();

  const selectedPackage: PackageInfoState = useSelector((state: ApplicationState) => state.packageInfo, shallowEqual);

  const selectedMsisdn: MsisdnState = useSelector((state: ApplicationState) => state.msisdn, shallowEqual);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorModalMessage, setErrorModalMessage] = React.useState<string>(t('validation.genericErrorMessage'));
  const [city, setCity] = useState('');
  const [districts, setDistricts] = useState<Array<any>>([]);
  const [streets, setStreets] = useState<Array<any>>([]);
  const [district_name, setDistrictName] = useState('');
  const [dealerAddress, setDealerAddress] = useState('');

  const cities = getSelectObjects([...citiesJSON].filter((item: any) => item.country_id === '245'));

  const [selected, setSelected] = useState('2');

  let defaultCityId = '202'; // 202 = Lefkoşa
  let defaultDistrictId = ''; // ERCAN HAVALİMANI
  const personalInfo: PersonalInfoState = useSelector((state: ApplicationState) => state.personalInfo, shallowEqual);

  useEffect(() => {
    const isValidErrors = () => Object.values(errors).filter(error => typeof error !== 'undefined').length > 0;
  }, [errors]);

  const defaultFormValues: PersonalInfoFormInputs = {
    nameSurname: personalInfo.nameSurname !== '' ? personalInfo.nameSurname : '',
    // countryCode:
    // 	personalInfo.countryCode !== "" ? personalInfo.countryCode : "+90",
    contactNumber: personalInfo.contactNumber !== '' ? personalInfo.contactNumber : '',
    idType: personalInfo.idType !== '' ? personalInfo.idType : '', // TCKN
    idNumber: personalInfo.idNumber !== '' ? personalInfo.idNumber : '',
    // occupation: personalInfo.occupation !== "" ? personalInfo.occupation : "26", // student
    dob: personalInfo.dob !== '' ? personalInfo.dob : '',
    // birthPlace: personalInfo.birthPlace !== "" ? personalInfo.birthPlace : "",
    // mothersName:
    // personalInfo.mothersName !== "" ? personalInfo.mothersName : "",
    fathersName: personalInfo.fathersName !== '' ? personalInfo.fathersName : '',
    // mothersMaidenName:
    // personalInfo.mothersMaidenName !== ""
    // ? personalInfo.mothersMaidenName
    // : "",
    email: personalInfo.email !== '' ? personalInfo.email : '',
    city: personalInfo.city !== '' ? personalInfo.city : defaultCityId,
    district: personalInfo.district !== '' ? personalInfo.district : defaultDistrictId,
    street: personalInfo.street !== '' ? personalInfo.street : '',
    streetName: personalInfo.streetName !== '' ? personalInfo.streetName : '',
    address: personalInfo.address !== '' ? personalInfo.address : '',
    cityName: personalInfo.cityName !== '' ? personalInfo.cityName : '',
    distirctName: personalInfo.distirctName !== '' ? personalInfo.distirctName : '',
    // nationality:
    // personalInfo.nationality !== "" ? personalInfo.nationality : "260",
    // gender: personalInfo.gender !== "" ? personalInfo.gender : "",
  };

  // const [countryCode, setCountryCode] = React.useState(
  // 	personalInfo.countryCode !== "" ? personalInfo.countryCode : "+90"
  // );
  const [age, setAge] = React.useState('260');
  const msisdn: MsisdnState = useSelector((state: ApplicationState) => state.msisdn, shallowEqual);

  const idTypes = getSelectObjects([...idTypesJSON]);
  const nationalities = moveToItemsWithKeysToBeginning(
    getSelectObjects([...nationalitiesJSON], 'label'),
    'value',
    ['260', '245'] // [TC, KKTC] to the TOP
  );

  const occupations = getSelectObjects([...occupationsJSON]);
  const genders = getSelectObjects([...gendersJSON]);

  const isIdNumberValid = (val: string) => {
    return validateIdNumber(val, '3');
  };
  //console.log('personalInfo.dob', personalInfo.dob)
  let [formValues, setFormValues] = useState({
    contactNumber: personalInfo.contactNumber,
    dob: personalInfo.dob ? personalInfo.dob : moment(Date.now()).format('YYYY-MM-DD'),
    email: personalInfo.email,
    fathersName: personalInfo.fathersName,
    idNumber: personalInfo.idNumber,
    nameSurname: personalInfo.nameSurname,
    idType: personalInfo.idType,
    school: personalInfo.school !== '' ? personalInfo.school : '',
  });
  //console.log('personalInfo formValues halli', formValues)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    console.log(value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
    //setAge(event.target.value as string);
  };
  const validatePhoneNumber = (value: any) => {
    const phonePattern = /^0[0-9]{10}$/; // 0 ile başlayan 11 haneli sadece rakam
    return phonePattern.test(value);
  };
  const onSubmit = async (data: any) => {
    data = formValues;
    if (!/^[0-9]*$/.test(data.contactNumber)) {
      setErrorModalMessage('İletişim numarasını sadece rakam girebilirsiniz.');
      setShowErrorModal(true);
      return;
    }
    if (!validatePhoneNumber(data.contactNumber)) {
      setErrorModalMessage('Geçersiz iletişim numarası numarası. Format: 05XXXXXXXXX');
      setShowErrorModal(true);
      return;
    }
    var dobString = moment(data.dob).format('DD.MM.YYYY');
    console.log(dobString)

    // Doğum tarihini moment ile tanınabilir formata dönüştürüyoruz
    var dob = moment(dobString, 'DD.MM.YYYY');

    // Şu andaki tarih (bugün)
    var currentDate = moment();

    // 18 yaş sınırını hesaplamak için bugünkü tarihten 18 yıl öncesini alıyoruz
    var eighteenYearsAgo = currentDate.subtract(18, 'years');

    if (data.idType.toString() === '2') {
      if (data.idNumber.length !== 11) {
        setErrorModalMessage(t('personalInfo.tcIdErr'));
        setShowErrorModal(true);
        return;
      }
    } else {
      if (data.idNumber.length !== 6 && data.idNumber.length !== 10) {
        setErrorModalMessage(t('personalInfo.kktcIdErr'));
        setShowErrorModal(true);
        return;
      }
    }
    if (data.dob === moment(Date.now()).format('DD.MM.YYYY')) {
      setErrorModalMessage(t('personalInfo.tooltipDOBText'));
      setShowErrorModal(true);
    } else if (dob.isAfter(eighteenYearsAgo)) {
      setErrorModalMessage(t('personalInfo.err18dob'));
      setShowErrorModal(true);
    } else {
      if (data.idType.toString() === '2') {
        const tcRes = await api.post(apiEndpoints.validateTCKimlik, {
          NAME: data.nameSurname,
          ID_NO: data.idNumber,
          BIRTH_DATE: moment(data.dob).format('DD.MM.YYYY'),
        });
        console.log('test', tcRes);
        if (tcRes.data === 'false') {
          setErrorModalMessage(t('personalInfo.valid_tc_err'));
          setShowErrorModal(true);
          return;
        }
      }
      const borcKontrolData = {
        NAME: data.nameSurname,
        IDENTITY_TYPE: data.idType,
        ID_NO: data.idNumber,
        FATHER_NAME: data.fathersName,
        // OCCUPATION: data.occupation,
        BIRTH_DATE: moment(data.dob).format('DD.MM.YYYY'), // DD.MM.YYYY
      };
      console.log('Borckontrol', borcKontrolData.BIRTH_DATE);
      console.log('data', data.dob);
      data.dob = moment(data.dob).format('DD.MM.YYYY');
      console.log('dataa', data.dob);

      dispatch({
        type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
      });
      dispatch({
        type: PersonalInfoActionTypes.SAVE_PERSONAL_INFO,
        payload: data,
      });

      const apiEndpoint = apiEndpoints.borcKontrol;

      api
        .post(apiEndpoint, borcKontrolData)
        .then(response => {
          const data = response.data;

          console.log('Borckontrol' + JSON.stringify(data));
          dispatch({
            type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
          });
          if (data.FRAUD_RESULT === 'SUCCESS') {
            history.push({
              pathname: routes.addressInfo,
              state: {
                hasPrev: true,
                queryCode: queryCode,
                operation: 'switch',
              },
            });
          } else {
            if (borcKontrolData.ID_NO === '885252') {
              history.push({
                pathname: routes.idInfo,
                state: {
                  hasPrev: true,
                  queryCode: queryCode,
                  operation: 'switch',
                },
              });
            } else {
              setErrorModalMessage(t('personalInfo.borcKontrolErrorText'));
              setShowErrorModal(true);
            }
          }
        })
        .catch(e => {
          setErrorModalMessage(t('personalInfo.borcKontrolErrorText'));
          setShowErrorModal(true);
          dispatch({
            type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
          });
        });
    }
  };

  const ErrorModal = () => (
    <Modal show={showErrorModal}>
      <div className="Modal__content PersonalInfo__modal">
        <IconError className="Modal__error-icon" />
        <h2 className="Modal__title">{t('modal.failTitle')}</h2>
        <p className="Modal__description">{errorModalMessage}</p>
        <button type="button" className="button Modal__button" onClick={() => setShowErrorModal(false)}>
          {t('modal.OK')}
        </button>
      </div>
    </Modal>
  );

  const onFail = () => {
    setErrorModalMessage(t('validation.genericErrorMessage'));
    setShowErrorModal(true);
  };

  interface LocationState {
    from: {
      pathname: string;
    };
    hasPrev?: boolean;
    queryCode?: string;
  }
  const location = useLocation<LocationState>();

  const hasPrev = useMemo(() => location.state?.hasPrev || '', [location]);
  const queryCode = useMemo(() => location.state?.queryCode || '', [location]);

  if (queryCode == null || queryCode == 'null' || queryCode == '' || queryCode == undefined) {
    //console.log("querycode empty ")
  } else {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?code=' + queryCode;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  if (hasPrev !== true) {
    return <Redirect to={routes.root} />;
  }

  return (
    <>
      <PageWrapper>
        <div className="grid">
          <div className="grid__rowProcess">
            <div className="grid__column grid__columnWitoutPadding">
              <ProcessBar step={3} />
            </div>
          </div>
        </div>
        <Container className="Container--align-contents-middle Container--narrow">
          {/* <h2 className="heading heading--navy heading--center">{t('personalInfo.title')}</h2> */}
          <div className="PersonalInfo__grid">
            <form onSubmit={handleSubmit(onSubmit, onFail)} className="PersonalInfo__form">
              <div className="grid">
                <h2 className="heading heading--navy">{t('personalInfo.subHeader')}</h2>
                <div className="grid__row">
                  <div className="grid__column" style={{ flex: 0.6, marginLeft: 0 }}>
                    <div className="grid__row">
                      <div className="grid__column">
                        <div className="form__group">
                          <div className="form__input-container">
                            <TextField
                              id="nameSurname"
                              name="nameSurname"
                              label={t('personalInfo.labelNameSurname')}
                              variant="outlined"
                              autoCapitalize="words"
                              type="text"
                              defaultValue={defaultFormValues.nameSurname}
                              onChange={handleChange}
                              fullWidth
                              inputRef={register({
                                required: true,
                                validate: {
                                  nameAndSurname: isTwoOrMoreWords,
                                  containsOnlyText: containsOnlyText,
                                },
                                maxLength: {
                                  value: 100,
                                  message: t('validation.maxCharacters100'),
                                },
                              })}
                            />
                            {errors.nameSurname && errors.nameSurname.type === 'required' && (
                              <p className="form__validation-error">{t('validation.allRequired')}</p>
                            )}
                            {errors.nameSurname && errors.nameSurname.type === 'nameAndSurname' && (
                              <p className="form__validation-error">{t('validation.surnameAlsoRequired')}</p>
                            )}
                            {errors.nameSurname && errors.nameSurname.type === 'maxLength' && (
                              <p className="form__validation-error">{t('validation.maxCharacters100')}</p>
                            )}
                            {errors.nameSurname && errors.nameSurname.type === 'containsOnlyText' && (
                              <p className="form__validation-error">{t('validation.containsOnlyText')}</p>
                            )}
                          </div>
                        </div>
                        <div className="form__group">
                          <TextField
                            id="idNumber"
                            name="idNumber"
                            label={t('personalInfo.labelIdNumber')}
                            variant="outlined"
                            autoCapitalize="words"
                            type="text"
                            defaultValue={defaultFormValues.idNumber}
                            fullWidth
                            onChange={handleChange}
                            inputRef={register({
                              required: true,
                              validate: {
                                isIdNumberValid: isIdNumberValid,
                              },

                              maxLength: {
                                value: 100,
                                message: t('validation.maxCharacters100'),
                              },
                            })}
                          />
                          {errors.idNumber && errors.idNumber.type === 'required' && (
                            <p className="form__validation-error">{t('validation.allRequired')}</p>
                          )}
                          {errors.idNumber && errors.idNumber.type === 'maxLength' && (
                            <p className="form__validation-error">{t('validation.maxCharacters100')}</p>
                          )}
                          {errors.idNumber && errors.idNumber.type === 'isIdNumberValid' && (
                            <p className="form__validation-error">{t('validation.requiredValidIdNumber')}</p>
                          )}
                        </div>

                        <div className="form__group">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">{t('personalInfo.labelIdType')}</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              name="idType"
                              value={formValues.idType}
                              label={t('personalInfo.labelIdType')}
                              onChange={handleChangeSelect}
                            >
                              <MenuItem value={2}>TC</MenuItem>
                              <MenuItem value={1}>KKTC</MenuItem>
                            </Select>
                          </FormControl>

                          {errors.idType && <p className="form__validation-error">{t('validation.idType')}</p>}
                        </div>

                        <div className="form__group">
                          <TextField
                            id="dob"
                            name="dob"
                            label={t('personalInfo.labelDOB')}
                            type="date"
                            defaultValue={moment(Date.now()).format('YYYY-MM-DD')}
                            fullWidth
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="grid__column">
                        {/* <div className="form__group">
                      <div className="form__input-container">
                        <TextField
                          id="fathersName"
                          name="fathersName"
                          label={t('personalInfo.labelFathersName')}
                          variant="outlined"
                          autoCapitalize="words"
                          type="text"
                          defaultValue={defaultFormValues.fathersName}
                          onChange={handleChange}
                          fullWidth
                          inputRef={register({
                            required: true,
                            validate: {
                              containsOnlyText: containsOnlyText,
                            },
                            maxLength: {
                              value: 100,
                              message: t('validation.maxCharacters100'),
                            },
                          })}
                        />
                        {errors.fathersName && errors.fathersName.type === 'required' && (
                          <p className="form__validation-error">{t('validation.allRequired')}</p>
                        )}
                        {errors.fathersName && errors.fathersName.type === 'maxLength' && (
                          <p className="form__validation-error">{t('validation.maxCharacters100')}</p>
                        )}
                        {errors.fathersName && errors.fathersName.type === 'containsOnlyText' && (
                          <p className="form__validation-error">{t('validation.containsOnlyText')}</p>
                        )}
                        {/* <div className="form__input-container">
												
											</div> */}
                        {/* </div>
                    </div>  */}

                        <div className="form__group">
                          <TextField
                            id="email"
                            label={t('personalInfo.labelEmail')}
                            name="email"
                            variant="outlined"
                            autoCapitalize="words"
                            type="text"
                            defaultValue={defaultFormValues.email}
                            fullWidth
                            onChange={handleChange}
                            inputRef={register({
                              required: true,
                              pattern: /^\S+@\S+$/i,

                              maxLength: {
                                value: 100,
                                message: t('validation.maxCharacters100'),
                              },
                            })}
                          />
                          {errors.email && errors.email.type === 'required' && (
                            <p className="form__validation-error">{t('validation.allRequired')}</p>
                          )}
                          {errors.email && errors.email.type === 'maxLength' && (
                            <p className="form__validation-error">{t('validation.maxCharacters100')}</p>
                          )}
                          {errors.email && errors.email.type === 'pattern' && (
                            <p className="form__validation-error">{t('validation.emailPattern')}</p>
                          )}
                        </div>

                        <div className="form__group">
                          <TextField
                            id="contactNumber"
                            name="contactNumber"
                            label={t('personalInfo.labelContactNumber')}
                            variant="outlined"
                            autoCapitalize="words"
                            type="number"
                            defaultValue={defaultFormValues.contactNumber}
                            onChange={handleChange}
                            fullWidth
                            inputRef={register({
                              required: true,
                              maxLength: {
                                value: 15,
                                message: t('validation.allRequired'),
                              },
                              minLength: {
                                value: 8,
                                message: t('validation.allRequired'),
                              },
                            })}
                          />
                        </div>
                        {errors.contactNumber && errors.contactNumber.type === 'required' && (
                          <p className="form__validation-error">{t('validation.allRequired')}</p>
                        )}
                        {errors.contactNumber && errors.contactNumber.type === 'minLength' && (
                          <p className="form__validation-error">{t('validation.allRequired')}</p>
                        )}
                        {errors.contactNumber && errors.contactNumber.type === 'maxLength' && (
                          <p className="form__validation-error">{t('validation.allRequired')}</p>
                        )}
                        <div className="form__group">
                          <TextField
                            id="school"
                            name="school"
                            label={t('personalInfo.school')}
                            variant="outlined"
                            autoCapitalize="words"
                            defaultValue={defaultFormValues.school}
                            onChange={handleChange}
                            fullWidth
                            inputRef={register({
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid__column" style={{ height: '10%', flex: 0.4 }}>
                    <div className="PersonalInfo__card">
                      <h2 className="PersonalInfo__cardHeader">{t('personalInfo.cardHeader')}</h2>
                      <div className="PersonalInfo__container">
                        <div className="PersonalInfo__box">{t('personalInfo.msisdn')}</div>
                        <div className="PersonalInfo__box" style={{ color: '#253342' }}>
                          {msisdn.number}
                        </div>
                      </div>
                      <div className="PersonalInfo__container">
                        <div className="PersonalInfo__box">{t('personalInfo.selectedPackage')}</div>
                        <div className="PersonalInfo__box" style={{ color: '#253342' }}>
                          {selectedPackage.name}
                        </div>
                      </div>
                      <div className="PersonalInfo__container">
                        <div className="PersonalInfo__box">{t('personalInfo.selectedPackagePrice')}</div>
                        <div className="PersonalInfo__box" style={{ color: '#253342' }}>
                          {selectedPackage.price}
                        </div>
                      </div>

                      <div className="PersonalInfo__buttons">
                        <button
                          type="submit"
                          className="button ChooseNumber__button"
                          style={{ width: '100%', marginTop: 10 }}
                          // onClick={onClickHandler}
                        >
                          {t('chooseNumber.continue')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <p className="text--color-808080 text--mt-100 text--pt-100 text--center PersonalInfo__textFamily">
            <Trans i18nKey="personalInfo.legalText">
              Yaptığınız yeni abonelik başvurunuz kapsamında toplanan kimlik, iletişim, abonelik bilgisi gibi kişisel verilerinizin Kişisel
              Verilerin Korunması Kanunu’nun kapsamında yer alan abonelik ilişkisinin ve sözleşmesinin kurulması amacıyla işlenmesinin
              zorunlu olmasından dolayı Turkcell tarafından işlenmekte, bu amaçla sınırlı olarak Kuzey Kıbrıs Turkcell’in iş ortaklarına
              aktarılmaktadır. Kişisel verilerinize ilişkin Aydınlatma Metni için{' '}
              <a
                href={
                  t('lang') === 'tr' ? 'https://www.kktcell.com/gizlilik-ve-guvenlik' : 'https://www.kktcell.com/en/privacy-and-security'
                }
                rel="noreferrer"
                target="_blank"
                className="link link--blue link--underline"
              >
                tıklayın
              </a>
              .
            </Trans>
          </p>
        </Container>
      </PageWrapper>
      <ErrorModal />
    </>
  );
};

export default PersonalInfoPage;
