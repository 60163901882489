import IconCallyou from "assets/svg/IconCallyou";
import IconDeliver from "assets/svg/IconDeliver";
import IconDelivery from "assets/svg/IconDelivery";
import IconForm from "assets/svg/IconForm";
import IconList from "assets/svg/IconList";
import IconMagnifier from "assets/svg/IconMagnifier";
import IconSelectPackage from "assets/svg/IconSelectPackage";
// import IconShop from "assets/svg/IconShop";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./style.scss";

interface Props {
	className?: string;
}

const TimeLine: React.FC<Props> = ({ className }) => {
	const { t } = useTranslation();
	return (
		<div className={"Timeline" + (className ? " " + className : "")}>
			<div className="Timeline__items">
				<div className="Timeline__item">
					<IconSelectPackage className="Timeline__item-icon" />
					<div className="Timeline__line"></div>
					<div className="Timeline__item-text">
						{t("landing.choosePackage")}
						<div className="Timeline__item-subText">
							{t("landing.choosePackageSub")}
						</div>
					</div>

				</div>
				<div className="Timeline__item">
					<IconForm className="Timeline__item-icon" />
					<div className="Timeline__line"></div>
					<div className="Timeline__item-text">
						{t("landing.fillForm")}
						<div className="Timeline__item-subText">
							{t("landing.fillFormSub")}
						</div>
					</div>
				</div>

				{/* <div className="Timeline__item">
					<IconCallyou className="Timeline__item-icon" />
					<div className="Timeline__line"></div>
					<div className="Timeline__item-text">
						{t("landing.callYou")}

						<div className="Timeline__item-subText">
							{t("landing.callYouSub")}
						</div>
					</div>


				</div> */}
				<div className="Timeline__item">
				<IconCallyou className="Timeline__item-icon" />
					<div className="Timeline__line"></div>
					<div className="Timeline__item-text">
						{t("landing.delivery")}
						<div className="Timeline__item-subText">
							{t("landing.deliverySub")}
						</div>
					</div>


				</div>
			</div>
		</div>
	);
};

export default TimeLine;
