import { Reducer } from "redux";
import { PersonalInfoState, PersonalInfoActionTypes } from "./types";

const initialState: any = {
	nameSurname: "",
	// countryCode: "",
	contactNumber: "",
	idType: "",
	idNumber: "",
	// occupation: "",
	dob: "",
	// birthPlace: "",
	fathersName: "",
	// mothersName: "",
	// mothersMaidenName: "",
	email: "",
	// nationality: "",
	// gender: "",
};

const reducer: Reducer<PersonalInfoState> = (state = initialState, action) => {
	switch (action.type) {
		case PersonalInfoActionTypes.SAVE_PERSONAL_INFO:
			return { ...state, ...action.payload };
		case PersonalInfoActionTypes.DELETE:
			return { ...state, ...initialState };
		default: {
			return state;
		}
	}
};

export default reducer;
