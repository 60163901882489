// {
// 	"id": "0",
// 	"country_id": "245",
// 	"name_tr": "DİĞER",
// 	"name_en": "OTHER"
// },

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Container from 'App/components/Container';
import PageWrapper from 'App/components/PageWrapper';
import './style.scss';
import Select from 'App/components/Select';
import { AddressInfoFormInputs } from 'type';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { AddressInfoActionTypes, AddressInfoState } from 'store/addressInfo/types';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import routes from 'App/routes';

import citiesJSON from 'data/cities.json';
import districtsJSON from 'data/districts.json';
import { getSelectObjects } from 'App/helpers/functions';
import { ResultActionTypes } from 'store/result/types';
import InputInfoTooltip from 'App/components/InputInfoTooltip';
import { LoadingSpinnerActionTypes } from 'store/loadingSpinner/types';
import api from 'App/api';
import { apiEndpoints } from 'App/api/endpoints';
import { cmsApiEndpoints } from 'App/api/endpoints';
import { ApplicationState } from 'store';
import { TextField } from '@mui/material';
import RadioButton from 'App/components/RadioButton';
import ProcessBar from 'App/components/ProcessBar';
import { MsisdnState } from 'store/msisdn/types';
import { PersonalInfoState } from 'store/personalInfo/types';

interface Props {}

const AddressInfoPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    errors,
    control,
    // reset,
    getValues,
    setValue,
  } = useForm<AddressInfoFormInputs>();

  const addressInfo: AddressInfoState = useSelector((state: ApplicationState) => state.addressInfo, shallowEqual);
  const msisdn: MsisdnState = useSelector((state: ApplicationState) => state.msisdn, shallowEqual);
  const personalInfo: PersonalInfoState = useSelector((state: ApplicationState) => state.personalInfo, shallowEqual);

  const history = useHistory();
  const dispatch = useDispatch();
  const [city, setCity] = useState('');
  const [districts, setDistricts] = useState<Array<any>>([]);
  const [streets, setStreets] = useState<Array<any>>([]);
  const [dealers, setDealers] = useState<Array<any>>([]);
  const [district_name, setDistrictName] = useState('');
  const [dealerAddress, setDealerAddress] = useState('');

  const cities = getSelectObjects([...citiesJSON].filter((item: any) => item.country_id === '245'));

  const [selected, setSelected] = useState('2');

  const fetchDealers = useCallback(
    (city_id: string) => {
      setDealerAddress('');
      // dispatch({
      //   type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
      // });

      const apiEndpoint = cmsApiEndpoints.GetDealerByFilter;

      // if (city_id === undefined || city_id === null || city_id.length === 0) city_id = defaultCityId;

      let cityName = cities.filter((item: any) => {
        return item.value === city_id;
      });

      var model;
      
      if (cityName !== undefined && cityName !== null && cityName.length > 0) {
        cityName = cityName[0].label.toLocaleLowerCase('tr-TR');
        cityName = cityName.charAt(0).toLocaleUpperCase('tr-TR') + cityName.slice(1);
        model = {
          City: cityName,
          SearchText: '',
          DealerType: '1',
        };

        const data = { model: model };
        api
          .post(apiEndpoint, data)
          .then(response => {
            const data = response.data;
            //	console.log("Result" + JSON.stringify(response));
            setDealers(data);
            console.log(data)
            // setDealerAddress(
            // 	data[0].address
            // );
          })
          .catch(e => {
            console.log('Loggg' + e);
          })
          .then(() => {
            dispatch({
              type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
            });
          });
      } else if (city_id === '0') {
        cityName = 'DİĞER';
        cityName = cityName.toLocaleLowerCase('tr-TR');
        cityName = cityName.charAt(0).toLocaleUpperCase('tr-TR') + cityName.slice(1);
        model = {
          City: cityName,
          SearchText: '',
          DealerType: '1',
        };
        let data = [];
        data.push({
          address: 'Ercan Havaalanı',
          id: 'Sarpkan Ercan',
          label: 'Sarpkan Ercan',
          mapModel: { lat: '35.15790923663754', lng: '33.503246158361435' },
          name_en: 'Sarpkan Ercan',
          name_tr: 'Sarpkan Ercan',
          value: 'Sarpkan Ercan',
        });
        setDealers(data);
        // setDealerAddress(
        // 	data[0].address
        // );
        dispatch({
          type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
        });
      }
    },
    [dispatch]
  );

  // SET DEFAULT TO 202 DISTRICTS
  useEffect(() => {
    fetchDealers(personalInfo.city);
  },[]);
  //
  // console.log('ttttt', getSelectObjects(
  // 	districtsJSON.filter((item: any) => {
  // 		return item.id === addressInfo.district;
  // 	})
  // )[0].label)
  const defaultFormValues: AddressInfoFormInputs = {
    city: personalInfo.city,
    district: personalInfo.district,
    street: personalInfo.street,
    streetName: personalInfo.streetName,
    address: personalInfo.address,
    choise: addressInfo.choise !== '' ? addressInfo.choise : '1',
    dealer: addressInfo.dealer !== '' ? addressInfo.dealer : '1',
    cityName: personalInfo.cityName,
    distirctName: '',
  };

  /** Disable direct access to form from url */
  interface LocationState {
    from: {
      pathname: string;
    };
    hasPrev?: boolean;
    queryCode?: string;
  }
  const location = useLocation<LocationState>();

  const hasPrev = useMemo(() => location.state?.hasPrev || false, [location]);
  const queryCode = useMemo(() => location.state?.queryCode || '', [location]);

  if (queryCode == null || queryCode == 'null' || queryCode == '' || queryCode == undefined) {
    console.log('querycode empty ');
  } else {
    var newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?code=' + queryCode;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  if (hasPrev !== true) {
    return <Redirect to={routes.root} />;
  }

  const resetResultStoreState = () => {
    dispatch({
      type: ResultActionTypes.DELETE,
    });
  };

  const onSubmit = (data: AddressInfoFormInputs) => {
    data.streetName = '';
    if (data.street !== '') {
      data.streetName = streets.find(obj => obj.value.toString() === data.street)?.label;
    }

    let cityName = cities.filter((item: any) => {
      return item.value === city;
    });

    if (cityName !== undefined && cityName !== null && cityName.length > 0) {
      data.cityName = cityName[0].label;
    }

    if (data.dealer !== '' || data.dealer !== undefined) {
      data.dealer = dealers.find(obj => obj.value.toString() === data.dealer)?.label;

      data.choise = selected;
      if (selected === '2') {
        if (data.dealer !== undefined) {
          dispatch({
            type: AddressInfoActionTypes.SAVE_ADDRESS_INFO,
            payload: data,
          });

          //resetResultStoreState();
          history.push({
            pathname: routes.idInfo,
            state: {
              hasPrev: true,
              queryCode: queryCode,
            },
          });
        }
      } else {
        dispatch({
          type: AddressInfoActionTypes.SAVE_ADDRESS_INFO,
          payload: data,
        });

        //resetResultStoreState();
        history.push({
          pathname: routes.idInfo,
          state: {
            hasPrev: true,
            queryCode: queryCode,
          },
        });
      }
    }
  };

  const onFail = () => {
    // setErrorModalMessage(t('validation.genericErrorMessage'));
    // setShowErrorModal(true);
  };
  console.log('Errors', errors);

  return (
    <PageWrapper>
      <div className="grid">
        <div className="grid__rowProcess">
          <div className="grid__column grid__columnWitoutPadding">
            <ProcessBar fromPage={msisdn.returnPage} step={msisdn.returnPage.length == 0 ? 3 : 2} />
          </div>
        </div>
      </div>
      <Container className="Container--align-contents-middle Container--narrow">
        <h2 className="heading heading--navy heading--center">{t('addressInfo.title')}</h2>
        {/* <div className="AddressInfo__description">
					<p>{t("addressInfo.enterDeliveryAddress")}</p>
					<p>{t("addressInfo.hotelNameRequired")}</p>
				</div> */}
        <div className="AddressInfo__form-container">
          <form onSubmit={handleSubmit(onSubmit, onFail)} className="AddressInfo__form">
            <div className="grid">
              <div className="grid__rowU">
                <div className="grid__columnU2">
                  <div className="form__group">
                    <div className="form__input-container">
                      <div className="AddressInfo__radio-group">
                        <RadioButton value="2" selected={selected} onChange={setSelected} className="NumberSelection__radio">
                          <div>{t('addressInfo.getFromStore')}</div>
                        </RadioButton>
                      </div>
                    </div>
                      <div className="grid__columnU">
                        <div className="grid__row AddressInfo__row">
                          <div className="grid__column">
                            <div className="form__group">
                              <label className="form__label" htmlFor="city">
                                {t('addressInfo.labelCity')}
                              </label>
                              <div className="form__input-container">
                                <Controller
                                  render={({ onChange, value, ref }) => (
                                    <Select
                                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        setCity(e.target.value);
                                        fetchDealers(e.target.value);
                                        return onChange(e.target.value);
                                      }}
                                      options={[...cities, { value: '0', label: 'LEFKOŞA ERCAN HAVALİMANI' }]}
                                      value={value}
                                      ref={ref}
                                      placeholder="Seçiniz"
                                    />
                                  )}
                                  name="city"
                                  rules={{ required: true }}
                                  control={control}
                                  defaultValue={defaultFormValues.city}
                                />

                                {errors.city && <p className="form__validation-error">{t('validation.cityRequired')}</p>}
                              </div>
                            </div>
                          </div>
                          <div className="grid__column">
                            <div className="form__group">
                              <label className="form__label" htmlFor="dealer">
                                {t('addressInfo.labelDealer')}
                              </label>
                              <div className="form__input-container">
                                <Controller
                                  render={({ value, onChange, ref }) => (
                                    <Select
                                      options={dealers}
                                      value={value}
                                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        if (e.target.value.length > 0) {
                                          setDealerAddress(
                                            dealers.filter((item: any) => {
                                              return item.id === e.target.value;
                                            })[0].address
                                          );
                                        }

                                        return onChange(e.target.value);
                                      }}
                                      placeholder="Seçiniz"
                                      ref={ref}
                                    />
                                  )}
                                  name="dealer"
                                  rules={{ required: true }}
                                  control={control}
                                  options={dealers}
                                  defaultValue={defaultFormValues.dealer}
                                />

                                {errors.dealer && <p className="form__validation-error">{t('validation.districtRequired')}</p>}
                                <p>
                                  {dealerAddress !== null && dealerAddress.length > 0 !== null && dealerAddress !== ''
                                    ? 'Adres: ' + dealerAddress
                                    : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="AddressInfo__buttons">
              <button type="submit" className="button AddressInfo__submit">
                {t('addressInfo.continue')}
              </button>
            </div>
          </form>
        </div>
      </Container>
    </PageWrapper>
  );
};

export default AddressInfoPage;
