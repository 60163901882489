import axios from "axios";

const instance = axios.create({
	 baseURL: "/",
//    baseURL: "http://localhost:3005",
	timeout: 30000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		"x-api-key": process.env.REACT_APP_BASE_API_KEY
	},
});

export default instance;
